import usesApi from 'chimera/all/mixins/usesApi'
import { getRecaptchaToken } from 'chimera/all/plugins/recaptcha/install'
import Cookies from 'js-cookie'
import { mapState } from 'vuex'

/**
 * Mixin to be used for posting leads.
 *
 * @returns {object}
 */
export default {
  /**
   * @returns {{isPostingLead: boolean}}
   */
  data() {
    return {
      isPostingLead: false,
    }
  },

  mixins: [usesApi],

  computed: {
    ...mapState({
      storeValue: (state) => state.lead.data[this.field],
    }),

    /**
     * Format lead
     * All entries with type Array will be concat to string.
     *
     * @returns {object}
     */
    formattedLead() {
      let lead = { ...this.$store.state.lead.data }
      Object.keys(lead).forEach((key) => {
        lead[key] = this.valueToString(lead[key].value)
      })

      lead = this.addSessionIdToLead(lead)
      lead = this.addVisitorIdToLead(lead)
      lead = this.addReferralToLead(lead)
      lead = this.addExperimentsToLead(lead)
      lead = this.addCountryToLead(lead)
      lead = this.addLanguageToLead(lead)
      lead = this.addCookiesToLead(lead)
      lead = this.addUserConversionUrlToLead(lead)
      lead = this.addGoogleConsentFieldsToLead(lead)

      return lead
    },
  },

  /**
   *
   */
  beforeDestroy() {
    this.isPostingLead = false
  },

  methods: {
    /**
     * Check current lead data if it has match with professionals
     *
     * @param {object} lead
     * @returns {Promise|undefined}
     */
    async postLead(lead = this.formattedLead) {
      if (this.isPostingLead) {
        return
      }

      this.isPostingLead = true

      lead = await this.addRecaptchaTokenToLead(lead)

      const config = {
        headers: {
          'Accept-Version': 'v2.0',
        },
        // We explicitly set the validateStatus to 200 because the response data is important.
        validateStatus: (status) => {
          return status === 200
        },
      }

      return this.apiPost('lead', lead, config)
        .then((response) => {
          const { status, data } = response
          // The case where the status is fail and code 1002 is actually a silent
          // success, as there already is a lead in our back-end which caused the duplicate
          // prevention to kick-in.
          if (status === 'fail' && data.errorCode !== 1002) {
            this.onInvalidLeadPost(data)
          } else {
            this.onValidLeadPost(data)
          }

          return response
        })
        .catch((error) => {
          this.onErrorLeadPost(error)
          this.isPostingLead = false
        })
        .finally(this.onFinallyLeadPost)
    },

    /**
     * This hook is called at the start of the onValid method and allows you to perform additional
     * code without having to override the onValid functionality.
     *
     * @param {object}  data
     * @param {string}  data.id
     * @param {number}  data.defaultPrice
     * @param {number}  data.revenueMade
     * @param {Array}   data.companies
     */
    beforeOnValidLeadPost({ id, defaultPrice, revenueMade, companies }) {
      if (id === undefined) {
        return
      }

      const recaptchaToken =
        this.$store.getters['lead/getData']('recaptcha-token')
      const tokenLength = recaptchaToken ? recaptchaToken?.length : 0
      this.$eventBus.emitRecaptchaTokenLengthEvent(tokenLength, id)
    },

    /**
     * When we've successfully submitted the lead.
     *
     * @param {object}  data
     * @param {string}  data.id
     * @param {number}  data.defaultPrice
     * @param {number}  data.revenueMade
     * @param {Array}   data.companies
     */
    onValidLeadPost({ id, defaultPrice, revenueMade, companies } = {}) {
      this.beforeOnValidLeadPost({
        id,
        defaultPrice,
        revenueMade,
        companies,
      })

      // There is an edge-case where the exact lead has already been submitted to the back-end recently
      // resulting in a 'silent' onValid call without id or revenueData. In this edge-case we shouldn't track
      // any conversions, but continue the flow.
      if (id !== undefined && revenueMade !== undefined) {
        const service = { ...this.$store.state.service }
        const leadData = { ...this.formattedLead }

        this.$eventBus.emitTransactionEvent(
          id,
          defaultPrice,
          revenueMade,
          service,
          leadData,
        )
      }

      // Reset lead & service store modules on success. No need to keep data.
      this.$store.commit('lead/reset')
      this.$store.commit('service/reset')
      this.$store.commit('experiments/reset')
      this.$store.commit('referral/reset')

      // Store revenue
      this.$store.commit('lead/updateResult', {
        id,
        defaultPrice,
        revenueMade,
        companies,
      })

      this.afterOnValidLeadPost({
        id,
        defaultPrice,
        revenueMade,
        companies,
      })
    },

    /**
     * This hook is called at the end of the onValid method and allows you to perform additional
     * code without having to override the onValid functionality.
     *
     * @param {*} value
     */
    afterOnValidLeadPost(value) {},

    /**
     * This hook is called at the start of the onInvalid method and allows you to perform additional
     * code without having to override the onInvalid functionality.
     *
     * @param {object} errorData
     */
    beforeOnInvalidLeadPost(errorData) {},

    /**
     * When we've failed to submit the lead.
     *
     * @param {object}  errorData
     */
    onInvalidLeadPost(errorData) {
      this.beforeOnInvalidLeadPost(errorData)

      const context = {
        code: errorData.errorCode || '-1',
        fields: Array.isArray(errorData.errorFields)
          ? errorData.errorFields
              .map(({ FieldIdentifier }) => FieldIdentifier || 'Undefined')
              .sort()
              .join(', ')
          : 'Undefined',
        message: errorData.errorMessage || 'Undefined',
      }

      this.$eventBus.emitErrorAppErrorEvent(
        new Error(
          `Errorcode ${context.code} - '${context.message}' for field(s): ${context.fields}`,
        ),
        context,
      )

      this.afterOnInvalidLeadPost(errorData)
    },

    /**
     * This hook is called at the end of the onInvalid method and allows you to perform additional
     * code without having to override the onInvalid functionality.
     *
     * @param {object} errorData
     */
    afterOnInvalidLeadPost(errorData) {},

    /**
     * This hook is called at the start of the onError method and allows you to perform additional
     * code without having to override the onError functionality.
     *
     * @param {*} value
     */
    beforeOnErrorLeadPost(value) {},

    /**
     * When something went wrong with submitting the lead.
     *
     * @param {Error} error
     */
    onErrorLeadPost(error) {
      this.beforeOnErrorLeadPost(error)
      this.$eventBus.emitErrorAppErrorEvent(error, {
        field: this.field,
        country: this.country,
      })
      this.afterOnErrorLeadPost(error)
    },

    /**
     * This hook is called at the end of the onError method and allows you to perform additional
     * code without having to override the onError functionality.
     *
     * @param {*} value
     */
    afterOnErrorLeadPost(value) {},

    /**
     * When submitting the lead has been successfully.
     */
    onFinallyLeadPost() {},

    /**
     * @param {object} lead
     * @returns {*}
     */
    addSessionIdToLead(lead) {
      const contextObject = this.$store.state.context
      if (!contextObject) {
        return lead
      }

      const context = {}
      const sessionId = contextObject && contextObject.sessionId
      if (sessionId) {
        context['session-id'] = sessionId
      }

      return { ...lead, ...context }
    },

    /**
     * @param {object} lead
     * @returns {*}
     */
    addVisitorIdToLead(lead) {
      const contextObject = this.$store.state.context
      if (!contextObject) {
        return lead
      }

      const context = {}
      const visitorId = contextObject && contextObject.visitorId
      if (visitorId) {
        context['visitor-id'] = visitorId
      }

      return { ...lead, ...context }
    },

    /**
     * @param {object} lead
     * @returns {*}
     */
    addReferralToLead(lead) {
      const referral = this.$store.state.referral
      if (!referral) {
        return lead
      }
      return { ...lead, ...referral }
    },

    /**
     * @param {object} lead
     * @returns {*}
     */
    addExperimentsToLead(lead) {
      const experiments = this.$store.getters['experiments/codeList']
      if (experiments.length < 1) {
        return lead
      }

      return {
        ...lead,
        experiments,
      }
    },

    /**
     * @param {object} lead
     * @returns {*}
     */
    addLanguageToLead(lead) {
      const language = this.$i18n.locale.substring(0, 2)
      return {
        ...lead,
        language,
      }
    },

    /**
     * @param {object} lead
     * @returns {*}
     */
    addCountryToLead(lead) {
      const country = this.$store.getters['context/get']('country')
      return {
        ...lead,
        country,
      }
    },

    /**
     * @param {object} lead
     * @returns {*}
     */
    addCookiesToLead(lead) {
      const propertyCookiesNames = {
        _fbc: 'facebook-cookie-fbc',
        _fbp: 'facebook-cookie-fbp',
      }
      const leadCookiesNames = ['_fbc', '_fbp']
      const leadCookies = {}

      leadCookiesNames.forEach((cookieName) => {
        const cookieValue = Cookies.get(cookieName)
        if (cookieValue) {
          leadCookies[propertyCookiesNames[cookieName]] = cookieValue
        }
      })

      return { ...lead, ...leadCookies }
    },

    /**
     * @param {object} lead
     * @returns {*}
     */
    addUserConversionUrlToLead(lead) {
      const conversionUrl = window.location.origin + this.$route.fullPath
      return {
        ...lead,
        'user-conversion-url': conversionUrl,
      }
    },

    /**
     * @param {object} lead
     * @returns {object}
     */
    async addRecaptchaTokenToLead(lead) {
      const recaptchaToken = await getRecaptchaToken()

      await this.$store.dispatch('lead/add', {
        key: 'recaptcha-token',
        value: recaptchaToken,
      })

      return {
        ...lead,
        'recaptcha-token': recaptchaToken,
      }
    },

    /**
     * @param {object} lead
     * @returns {lead&{'google-consent-ad-user-data': string, 'google-consent-ad-personalization': string, 'google-consent-ad-storage': string, 'google-consent-analytics-storage': string}}
     */
    addGoogleConsentFieldsToLead(lead) {
      const googleConsentFields = (() => {
        const latestConsent = {}

        window?.dataLayer?.forEach((entry) => {
          if (typeof entry === 'object' && entry[0] === 'consent' && entry[2]) {
            const consentData = entry[2]
            Object.keys(consentData).forEach((key) => {
              latestConsent[key] = consentData[key]
            })
          }
        })

        return latestConsent
      })()

      const regexGrantedOrDenied = /^(granted|denied)/g
      const googleConsentAdStorage = String(googleConsentFields?.ad_storage)
      const googleConsentAdPersonalization = String(
        googleConsentFields?.ad_personalization,
      )
      const googleConsentAdUserData = String(googleConsentFields?.ad_user_data)
      const googleConsentAnalyticsStorage = String(
        googleConsentFields?.analytics_storage,
      )

      return {
        ...lead,
        ...(googleConsentAdStorage.match(regexGrantedOrDenied) && {
          'google-consent-ad-storage': googleConsentAdStorage,
        }),
        ...(googleConsentAdPersonalization.match(regexGrantedOrDenied) && {
          'google-consent-ad-personalization': googleConsentAdPersonalization,
        }),
        ...(googleConsentAdUserData.match(regexGrantedOrDenied) && {
          'google-consent-ad-user-data': googleConsentAdUserData,
        }),
        ...(googleConsentAnalyticsStorage.match(regexGrantedOrDenied) && {
          'google-consent-analytics-storage': googleConsentAnalyticsStorage,
        }),
      }
    },
  },
}
